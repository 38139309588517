export const getEnvironment = () => {
  const origin = window.location.origin;

  const environment = origin.includes("localhost")
    ? "local"
    : origin.includes("vdi-wsl")
    ? "local"
    : origin.includes("drawbridgeplatformdev")
    ? "dev"
    : origin.includes("drawbridgeplatformqa")
    ? "qa"
    : origin.includes("drawbridgeplatformstg")
    ? "stg"
    : origin.includes("drawbridgeplatform.com")
    ? "prod"
    : undefined;

  return environment;
};

export const isEnvironment = (environments) => {
  const environment = getEnvironment();
  return environments?.includes(environment?.toUpperCase());
};
