import { getEnvironment } from "utilities/environment";
import extractModuleEnvKeyName from "./functions/extractModuleEnvKeyName";

const getTempStorageAccountEnvMap = (envs) => {
  const hostname = window.location.hostname;
  return {
    "local": `http://${hostname}:`,
    "dev": envs.MODULE_DOMAIN_DEV,
    "qa": envs.MODULE_DOMAIN_QA,
    "stg": envs.MODULE_DOMAIN_STG,
    "prod": envs.MODULE_DOMAIN_PROD
  }
}

const attachEnvsToWindowsObj = (envs) => {
  window.db.env = envs;
  const environment = getEnvironment();
  const moduleDomain = getTempStorageAccountEnvMap(envs)[environment];
  const moduleNameKeys = Object.keys(envs).filter((key) => key.startsWith("MODULE_NAME"));
  for (const key of moduleNameKeys) {
    const moduleEnvKeyName = extractModuleEnvKeyName(key) // ex: PROGRAM_MANAGEMENT from MODULE_NAME_PROGRAM_MANAGEMENT
    if (environment === "local") {
      const modulePort = envs[`PORT_${moduleEnvKeyName}`];
      window[`MODULE_PATH_${moduleEnvKeyName}`] = `${moduleDomain}${modulePort}`;
    }
    else {
      const pathPrefix = envs.MODULE_PATH_PREFIX;
      window[`MODULE_PATH_${moduleEnvKeyName}`] = `${moduleDomain}${pathPrefix}${envs[key]}`;
    }
  }
};
export default attachEnvsToWindowsObj;
