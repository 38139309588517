const extractModuleEnvKeyName = (key) => {
  const moduleEnvSplitArray = key.split("_");
  let moduleEnvKeyName = "";

  for (let i = 2; i < moduleEnvSplitArray.length; i++) {
    moduleEnvKeyName += moduleEnvSplitArray[i];

    if (i !== moduleEnvSplitArray.length - 1) {
      moduleEnvKeyName += "_";
    }
  }

  return moduleEnvKeyName;
}

export default extractModuleEnvKeyName;